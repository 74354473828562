import {computed, Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Branch, CreateStoreRequest} from '../core/branch.type';

@Injectable({
  providedIn: 'root',
})
export class BranchService {


  #state = signal<any>({
    isLoading: true,
    data: [],
  });

  private readonly API_URL: string = "https://dev.api.pavlova.pulpo.group/api";

  #branches = signal<Branch[]>([]);
  public branches = computed(this.#branches);

  constructor(private http: HttpClient) {
    this.fetchBranches();
  }

  fetchBranches() {
    this.http.post<{ content: Branch[] }>(this.API_URL + '/branches/search', {
      "query": {"name": "", "municipalities": [], "zones": []},
      "page": {"page": 1, "size": 100}
    }).subscribe(
      {
        next: (result: { content: Branch[] }) => {
          this.#branches.set(result.content);
        }
      }
    );

  }

  createBranch(branchData: CreateStoreRequest) {
    return this.http.post(this.API_URL + '/branches', branchData);
  }


}
